
import _, { debounce } from 'lodash'
import { defineAsyncComponent, defineComponent } from 'vue'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'

export default defineComponent({
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    DarkstoreSettingsInput: defineAsyncComponent(() => import('@/views/account/darkstores/components/DarkstoreSettingsInput.vue')),
    Popper: defineAsyncComponent(() => import('vue3-popper'))
  },
  props: {
    darkstore: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: {
        max_threshold_waiting_time: 0,
        max_orders_assigned: 0,
        max_distance_from_dark_store: 0,
        email_address: null,
        score_divisor: null,
        interval_time: 0,
        interval_weight: 0,
        stop_auto_assignment: false,
        delivered_orders_count_weight: 0,
        delivered_orders_distance_weight: 0,
        waiting_time_at_the_darkstore_weight: 0,
        stop_auto_clock_out: false
      },
      settingsFields: ['delivered_orders_count_weight', 'delivered_orders_distance_weight', 'waiting_time_at_the_darkstore_weight'],
      delivered_orders_count_weight_lock: false,
      delivered_orders_distance_weight_lock: false,
      waiting_time_at_the_darkstore_weight_lock: false,
      distributions: [
        { label: this.$t('darkstoreSettings.multiplication'), value: 1 },
        { label: this.$t('darkstoreSettings.division'), value: 2 }
      ],
      onSettingsInputHandler: debounce((input) => {
        this.onSettingsFieldHandler(input)
      }, 500)
    }
  },
  methods: {
    async updateSettings () {
      await this.$store.dispatch(`darkstores/${[DarkStoresActions.UPDATE_DARKSTORES_SETTINGS]}`, {
        settingsId: this.darkstore.darkstoreSetting[0].id,
        payload: {
          ...this.form,
          max_threshold_waiting_time: this.form.max_threshold_waiting_time * 60,
          interval_time: this.form.interval_time * 60,
          delivered_orders_count_weight: this.form.delivered_orders_count_weight / 100,
          delivered_orders_distance_weight: this.form.delivered_orders_distance_weight / 100,
          waiting_time_at_the_darkstore_weight: this.form.waiting_time_at_the_darkstore_weight / 100
        }
      })
      this.$emit('updated')
    },
    setBubble (range:any, bubble:any) {
      const val = range.value
      const min = range.min ? range.min : 0
      const max = range.max ? range.max : 100
      const newVal = Number(((val - min) * 100) / (max - min))
      bubble.innerHTML = val
      // Sorta magic numbers based on size of the native UI thumb
      bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`
    },
    onSettingsFieldHandler (field: string) {
      let keep = 0
      let restOfTheFieldCount = this.settingsFields.length - 1
      this.settingsFields.map((sf) => {
        const sfFieldValue = _.get(this.form, sf, 0) as any
        const isLocked = _.get(this, sf + '_lock')
        if (sf !== field && isLocked) {
          restOfTheFieldCount = restOfTheFieldCount - 1
          keep += sfFieldValue
        }
      })

      if (restOfTheFieldCount) {
        const fieldValue = _.get(this.form, field, 0) as any
        const remaining = (100 - keep) - fieldValue
        const perVal = remaining / restOfTheFieldCount

        this.settingsFields.map((sf:any) => {
          if (sf !== field) {
            const isLocked = _.get(this, sf + '_lock')
            if (!isLocked) {
              this.form = {
                ...this.form,
                [sf]: perVal
              }
            }
          }
        })
      }
    }
  },
  computed: {
    availableFieldCount (vm:any) {
      let count = 3
      if (vm.delivered_orders_count_weight_locked) count--
      if (vm.delivered_orders_distance_weight_locked) count--
      if (vm.waiting_time_at_the_darkstore_weight_locked) count--
      return count
    },
    total () {
      return Number(this.form.delivered_orders_count_weight) +
      Number(this.form.delivered_orders_distance_weight) +
      Number(this.form.waiting_time_at_the_darkstore_weight)
    }
  },
  watch: {
    form: {
      handler (newVal) {
        if (newVal.max_threshold_waiting_time < 0) {
          this.form.max_threshold_waiting_time = 0
        }
        if (newVal.max_orders_assigned < 0) {
          this.form.max_orders_assigned = 0
        }
        if (newVal.max_distance_from_dark_store < 0) {
          this.form.max_distance_from_dark_store = 0
        }
        if (newVal.interval_time < 0) {
          this.form.interval_time = 0
        }
        if (newVal.delivered_orders_count_weight < 0) {
          this.form.delivered_orders_count_weight = 0 as any
        }
        if (newVal.delivered_orders_distance_weight < 0) {
          this.form.delivered_orders_distance_weight = 0 as any
        }
        if (newVal.waiting_time_at_the_darkstore_weight < 0) {
          this.form.waiting_time_at_the_darkstore_weight = 0 as any
        }
      },
      deep: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (Array.isArray(this.darkstore.darkstoreSetting) && this.darkstore.darkstoreSetting.length) {
        this.form = {
          max_threshold_waiting_time: this.darkstore.darkstoreSetting[0].max_threshold_waiting_time,
          max_orders_assigned: this.darkstore.darkstoreSetting[0].max_orders_assigned,
          email_address: this.darkstore.darkstoreSetting[0].email_address,
          max_distance_from_dark_store: this.darkstore.darkstoreSetting[0].max_distance_from_dark_store,
          score_divisor: this.darkstore.darkstoreSetting[0]?.score_divisor?.value ?? null,
          interval_time: this.darkstore.darkstoreSetting[0].interval_time,
          interval_weight: this.darkstore.darkstoreSetting[0].interval_weight,
          stop_auto_assignment: this.darkstore.darkstoreSetting[0].stop_auto_assignment,
          stop_auto_clock_out: this.darkstore.darkstoreSetting[0].stop_auto_clock_out,
          delivered_orders_count_weight: this.darkstore.darkstoreSetting[0].delivered_orders_count_weight * 100,
          delivered_orders_distance_weight: this.darkstore.darkstoreSetting[0].delivered_orders_distance_weight * 100,
          waiting_time_at_the_darkstore_weight: this.darkstore.darkstoreSetting[0].waiting_time_at_the_darkstore_weight * 100
        }
      }

      if (this.$refs.range && this.$refs.rangeV) {
        const range = this.$refs.range as any
        const rangeV = this.$refs.rangeV as any

        const setValue = () => {
          const newValue = Number((range.value - range.min) * 100 / (range.max - range.min))
          const newPosition = 10 - (newValue * 0.2)
          rangeV.innerHTML = `<div class="text-center text-white text-xs font-bold">${range.value}</div>`
          rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`
        }
        range.addEventListener('input', setValue)
        this.$nextTick(() => {
          setValue()
        })
      }
    })
  }
})
